import React, {useEffect, useState} from 'react';
import Triangle from './Triangle';
import PlayerList from './PlayerList';
import Footer from "./Footer";
import './App.css';

function App() {
  const [players, setPlayers] = useState([]);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [turnCount, setTurnCount] = useState(0);
  const [winner, setWinner] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [initialScores, setInitialScores] = useState([]);

  const addPlayer = (name) => {
    setPlayers([...players, { name, score: 0, lastTwelvesShots : [] }]);
    setInitialScores([...initialScores, 0]);
  };

  const removePlayer = (index) => {
    const updatedPlayers = players.filter((_, i) => i !== index);
    const updatedInitialScores = initialScores.filter((_, i) => i !== index);

    setPlayers(updatedPlayers);
    setInitialScores(updatedInitialScores);
    // Réinitialiser l'index du joueur actuel si nécessaire
    if (currentPlayerIndex >= updatedPlayers.length) {
      setCurrentPlayerIndex(0);
    }
  };

  const startGame = () => {
    // Réinitialiser les scores des joueurs à zéro
    const resetPlayers = players.map(player => ({ ...player, score: 0, last12Shots: [] }));

    // Mettre à jour l'état des joueurs avec les scores réinitialisés
    setPlayers(resetPlayers);

    // Démarrer le jeu
    setGameStarted(true);
    // Réinitialiser les scores initiaux
    setInitialScores(resetPlayers.map(() => 0));
  };

  const handleTurnEnd = () => {
    if (players.length > 1) {
      setCurrentPlayerIndex((currentPlayerIndex + 1) % players.length);
    }
    // Réinitialiser les coups du joueur actuel
    const updatedPlayers = players.map((player, i) => {
      if (i === currentPlayerIndex) {
        return {
          ...player,
          last12Shots: [], // Réinitialiser les coups pour le joueur actuel
        };
      }
      return player;
    });
    setPlayers(updatedPlayers);
    setTurnCount(0);
    setInitialScores(players.map(player => player.score));
  };

  useEffect(() => {
    if (turnCount === 12) {
      handleTurnEnd();
    }
  }, [turnCount]);

  const updateScore = (points) => {
    const newPlayers = players.map((player, i) => {
      if (i === currentPlayerIndex) {
        const newShots = Array.isArray(player.last12Shots) ? [...player.last12Shots, points].slice(-12) : [points];
        const updatedScore = player.score + points;
        if (updatedScore > 1000) {
          const updatedPlayer = { ...player, score: initialScores[i], last12Shots: [] };
          handleTurnEnd();
          return updatedPlayer;
        }
        return { ...player, score: updatedScore, last12Shots: newShots };
      }
      return player;
    });
    setPlayers(newPlayers);

    if (newPlayers[currentPlayerIndex].score === 1000) {
      setWinner(newPlayers[currentPlayerIndex].name);
    } else if (newPlayers[currentPlayerIndex].score > 1000) {
      handleTurnEnd();
    }
    setTurnCount(turnCount + 1);
  };

  const undoLastShot = () => {
    setPlayers(players.map((player, i) => {
      if (i === currentPlayerIndex) {
        const newShots = [...player.last12Shots];
        const lastShot = newShots.pop();
        const updatedScore = player.score - (lastShot || 0); // Gestion de la valeur undefined

        return { ...player, score: updatedScore, last12Shots: newShots };
      }
      return player;
    }));

    setTurnCount(turnCount > 0 ? turnCount - 1 : 0); // Décrémenter le compteur de tours
  };

  const resetGame = () => {
    setCurrentPlayerIndex(0);
    setTurnCount(0);
    setWinner(null);
    setGameStarted(false);
    setInitialScores(players.map(player => player.score));
  };

  // Fonction pour déterminer si le bouton d'annulation doit être désactivé
  const isUndoDisabled = !Array.isArray(players[currentPlayerIndex]?.last12Shots) || players[currentPlayerIndex].last12Shots.length === 0;

  return (
      <div id="root">
      <div className="app-container">
        <h1>Pile mille</h1>
        {winner ? (
            <>
              <h2>Félicitations {winner}, tu as gagné !</h2>
              <button onClick={resetGame}>Nouvelle partie</button>
            </>
        ) : (
            <>
              <PlayerList
                  players={players}
                  onAddPlayer={addPlayer}
                  onRemovePlayer={removePlayer}
                  gameStarted={gameStarted}
              />
              {players.length > 0 && !gameStarted && (
                  <button onClick={startGame}>Commencer la partie</button>
              )}
              {gameStarted && players.length > 0 && (
                  <>
                    <h3>{players[currentPlayerIndex].name}, c'est à toi de jouer</h3>

                    <Triangle onScore={updateScore} />

                    <button className="miss" onClick={() => { updateScore(0); }}>0</button>

                    <button
                        onClick={undoLastShot}
                        disabled={isUndoDisabled} // Désactiver le bouton si la liste des coups est vide
                    >
                      Annuler le dernier coup
                    </button>

                    <div className="shots-history">
                      <h4>Coups précédents : </h4>
                      <ul>
                        {Array.isArray(players[currentPlayerIndex]?.last12Shots) && players[currentPlayerIndex].last12Shots.map((shot, index) => (
                            <li key={index}>{shot}</li>
                        ))}
                      </ul>
                    </div>


                  </>
              )}
            </>
        )}
      </div>
      <Footer/>
      </div>
  );
}

export default App;
