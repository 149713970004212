import React from "react";
import version from "./version";
import './Footer.css'

function Footer(){
    return (
        <footer className="footer">
            <p>Version : {version}</p>
        </footer>
    );
}

export default Footer;