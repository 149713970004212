import React, { useState } from 'react';
import './PlayerList.css';

function PlayerList({ players, onAddPlayer, gameStarted, onRemovePlayer }) {
    const [newPlayerName, setNewPlayerName] = useState('');

    const handleAddPlayer = (e) => {
        e.preventDefault();
        if (newPlayerName.trim() !== '') {
            onAddPlayer(newPlayerName);
            setNewPlayerName('');
        }
    };

    return (
        <div className="player-list">
            <h2>Joueurs</h2>
            {players.map((player, index) => (
                <div key={index} className="player-item">
                    {player.name} - {player.score} points
                    {!gameStarted && (
                        <button onClick={() => onRemovePlayer(index)} className="remove-button">X</button>
                    )}
                </div>
            ))}
            {!gameStarted && (
                <form className="add-player-form" onSubmit={handleAddPlayer}>
                    <input
                        type="text"
                        value={newPlayerName}
                        onChange={(e) => setNewPlayerName(e.target.value)}
                        placeholder="Nom du joueur"
                    />
                    <button type="submit">Ajouter un joueur</button>
                </form>
            )}
        </div>
    );
}

export default PlayerList;
