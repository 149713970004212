import React from 'react';
import './Triangle.css';

function Triangle({ onScore }) {
    const levels = [
        { points: 75 },
        { points: 40 },
        { points: 30 },
        { points: 25 },
    ];

    return (
        <div className="triangle">
            {levels.map((level, index) => (
                <div
                    key={index}
                    className="level"
                    onClick={() => onScore(level.points)}
                >
                    {level.points}
                </div>
            ))}
            <div className="bottom-line">
                <div className="bottom-line-zone" onClick={() => onScore(10)}>10</div>
                <div className="bottom-line-zone" onClick={() => onScore(5)}>5</div>
                <div className="bottom-line-zone" onClick={() => onScore(10)}>10</div>
            </div>
        </div>
    );
}

export default Triangle;
